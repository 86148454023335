import { INQUIRY, RESET_APP } from '../actions';

const initial_state = {
  inquiries: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case INQUIRY.INQUIRIES:
      return { ...state, inquiries: action.load };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
