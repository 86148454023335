import { BUSINESS, USER } from '../actions';

const initialState = {
  businesses: [],
  activeBusiness: {},
  isSwitchingActivityCenter: false,
};

export default (state = initialState, { type, load }) => {
  switch (type) {
    case BUSINESS.UPDATE_BUSINESS:
      return { ...state, activeBusiness: { ...state.activeBusiness, ...load } };
    case BUSINESS.UPDATE_CONNECTED_BUSINESSES:
      return { ...state, businesses: [...load] };
    case BUSINESS.SWITCHING_BUSINESS:
      return { ...state, isSwitchingActivityCenter: !state.isSwitchingActivityCenter };
    case USER.LOGOUT:
      return { ...initialState };
    default:
      return { ...state };
  }
};
