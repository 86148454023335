import { CHAT } from '../actions';

const initialState = {
  client: null,
  inbox: [],
  currentChannel: null,
  messages: [],
  showLoader: false,
  showInboxLoader: false,
};
const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT.CONNECT:
      return {
        ...state,
        client: action.load,
      };
    case CHAT.FETCH_INBOX:
      return {
        ...state,
        inbox: action.load,
      };
    case CHAT.GET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: action.load,
      };
    case CHAT.FETCH_MESSAGES:
      return {
        ...state,
        messages: action.load,
      };
    case CHAT.TOGGLE_LOADER:
      return {
        ...state,
        showLoader: action.load,
      };
    case CHAT.TOGGLE_INBOX_LOADER:
      return {
        ...state,
        showInboxLoader: action.load,
      };
    case CHAT.SEND_MESSAGE: {
      const messageList = [...state.messages];
      const msgIndex = messageList.findIndex(item => item._id === action.load.state.sid);
      if (msgIndex < 0) {
        messageList.push({
          _id: action.load.state.sid,
          text: action.load.state.body,
          user: {
            _id: action.load.state.author,
          },
          createdAt: action.load.state.timestamp,
        });
      }

      return {
        ...state,
        messages: messageList,
      };
    }

    case CHAT.RESET_CHAT:
      return {
        ...state,
        currentChannel: null,
        inbox: [],
        messages: [],
      };
    default:
      return state;
  }
};

export default chatReducer;
