export const USER = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  DETAILS: 'DETAILS',
  PHONE_VERIFIED: 'PHONE_VERIFIED',
  PROFILE_IMAGE_PERCENTAGE: 'PROFILE_IMAGE_PERCENTAGE',
  UPLOAD_PROFILE_IMAGE: 'UPLOAD_PROFILE_IMAGE',
  OTP_SENT: 'OTP_SENT',
  UPDATE: 'UPDATE',
};

export const TOAST = {
  TOAST: 'TOAST',
};

export const BUSINESS = {
  UPDATE_CONNECTED_BUSINESSES: 'UPDATE_CONNECTED_BUSINESSES',
  UPDATE_BUSINESS: 'UPDATE_BUSINESS',
  SWITCHING_BUSINESS: 'SWITCHING_BUSINESS',
};

export const ACTIVITES = {
  ACTIVITES: 'ACTIVITES',
  ACTIVITY: 'ACTIVITY',
  ACTIVITY_DETAILS: 'ACTIVITY_DETAILS',
};

export const PEOPLE = {
  PARRENT: 'PARRENT',
  KID: 'KID',
  COACH: 'COACH',
  PEOPLE_DETAILS: 'PEOPLE_DETAILS',
};

export const DISCOUNTS = {
  DISCOUNTS: 'DISCOUNTS',
  ADDED: 'ADDED_DISCOUNT',
  DISCOUNT_DETAILS: 'DISCOUNT_DETAILS',
};

export const SUSBSCRIPTION = {
  SUSBSCRIPTION: 'SUSBSCRIPTION',
  CURRENT_SUBSCRIPTION: 'CURRENT_SUBSCRIPTION',
};

export const TRANSACTION = {
  TRANSACTION: 'TRANSACTION',
};

export const DASHBOARD = {
  DASHBOARD: 'DASHBOARD',
};

export const RESET_APP = 'RESET_APP';

export const PAYMENT = {
  PAYMENT_METHODS: 'PAYMENT_METHODS',
};

export const CHAT = {
  CONNECT: 'CONNECT',
  FETCH_INBOX: 'FETCH_INBOX',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  GET_CURRENT_CHANNEL: 'GET_CURRENT_CHANNEL',
  SEND_MESSAGE: 'SEND_MESSAGE',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  TOGGLE_INBOX_LOADER: 'TOGGLE_INBOX_LOADER',
  RESET_CHAT: 'RESET_CHAT',
};

export const INQUIRY = {
  INQUIRIES: 'INQUIRIES',
};

export const ATTENDEES = {
  FETCH_ATTENDEES_ON_DATE: 'FETCH_ATTENDEES_ON_DATE',
  TOGGLE_ATTENDEES_MODAL: 'TOGGLE_ATTENDEES_MODAL',
  TOGGLE_CALENDAR_HOLIDAYS_MODAL: 'TOGGLE_CALENDAR_HOLIDAYS_MODAL',
  SET_HOLIDAYS: 'SET_HOLIDAYS',
};

export const MAKEUP_CLASS = {
  TOGGLE_HAS_NEW_MAKEUP_CLASS: 'TOGGLE_HAS_NEW_MAKEUP_CLASS',
};
