import * as React from 'react';
import InvoiceWithReferralCodeModal from '../pages/modals/InvoiceWithReferralCodeModal';

const InvoiceServiceContext = React.createContext(Promise);

export const useInvoiceModal = () => React.useContext(InvoiceServiceContext);

export const InvoiceServiceProvider = ({ children }) => {
  const [invoiceProps, setInvoiceProps] = React.useState(null);

  const awaitingPromiseRef = React.useRef();

  const openInvoice = options => {
    setInvoiceProps(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setInvoiceProps(null);
  };

  return (
    <>
      <InvoiceServiceContext.Provider value={openInvoice}>
        {children}
      </InvoiceServiceContext.Provider>

      <InvoiceWithReferralCodeModal
        show={Boolean(invoiceProps)}
        onHide={handleClose}
        {...invoiceProps}
      />
    </>
  );
};
