import { USER, TOAST, DASHBOARD } from './index';
import commonServices from '../../services/apiServices';
import { hasNewMakeupClass } from './makeupClassActions';

export const userLogin = data => dispatch => {
  dispatch({ type: USER.LOGIN, load: data });
};

export const updateUser = data => dispatch => {
  dispatch({ type: USER.UPDATE, load: data });
};

// OLD ACTIONS

export const HideToast = () => dispatch => {
  dispatch({
    type: TOAST.TOAST,
    load: { type: 'hide', message: '', show: false, position: 'bottom-right' },
  });
};

export const forgotPassword = (data, history) => dispatch => {
  commonServices
    .httpPostCall('/forgotPassword', data)
    .then(result => {
      localStorage.setItem('otptoken', result.data.data.token);
      history.push('/verify-otp');
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'OTP sent',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else if (err.response.data && err.response.data.errors) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.errors[0].msg,
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const verifyOtp = (data, history) => dispatch => {
  commonServices
    .httpPatchCall('/verifyOtp', data)
    .then(result => {
      localStorage.removeItem('otptoken');
      localStorage.setItem('passwordToken', result.data.data.password_token);
      history.push('/reset-password');
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'OTP Verified',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else if (err.response.data && err.response.data.errors) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.errors[0].msg,
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const resetPassword = (data, history) => dispatch => {
  commonServices
    .httpPatchCall('/changePassword', data)
    .then(result => {
      localStorage.removeItem('passwordToken');
      history.push('/login');
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'Password changed, Please login',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};
/* change password */

export const changePassword = data => dispatch => {
  commonServices
    .httpPatchCall('/setPassword', data)
    .then(result => {
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'Password changed.',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const fetchProfile = () => dispatch => {
  commonServices
    .httpGetCall('/fetchProfile')
    .then(result => {
      dispatch({ type: USER.DETAILS, load: result.data.data.profile });
      document.title = `Twishr | ${result?.data?.data?.profile?.business?.googleName} Admin`;
      if (result.data.data.hasNewMakeupClass) {
        dispatch(hasNewMakeupClass(true));
      }

      if (window.location.pathname.includes('not-verified')) {
        window.location.href = '/dashboard';
      }
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else if (!window.location.pathname.includes('not-verified')) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const updateProfile = data => dispatch => {
  commonServices
    .httpPutCall('/editProfile', data)
    .then(result => {
      dispatch(fetchProfile());
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'Profile updated',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const uploadProfileImage = data => dispatch => {
  dispatch({ type: USER.UPLOAD_PROFILE_IMAGE, load: true });
  const uploadProgress = progressEvent => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    if (percentCompleted === 100) {
      dispatch({ type: USER.UPLOAD_PROFILE_IMAGE, load: false });
    }

    dispatch({
      type: TOAST.TOAST,
      load: { type: 'hide', message: '', show: false, position: 'bottom-right' },
    });
    dispatch({ type: USER.PROFILE_IMAGE_PERCENTAGE, load: percentCompleted });
  };

  commonServices
    .httpPatchCall('/updateUserProfileImage', data, uploadProgress, true, 'processing')
    .then(result => {
      dispatch(fetchProfile());

      // dispatch({
      //   type: TOAST.TOAST,
      //   load: {
      //     type: 'success',
      //     message: 'Profile updated',
      //     show: true,
      //     position: 'bottom-right',
      //   },
      // });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const updatePhotosAndCertiificate = data => dispatch => {
  commonServices
    .httpPutCall('/updatePhotosAndCertiificate', data, true, 'processing')
    .then(result => {
      dispatch(fetchProfile());
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'Profile updated',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const fetchDashboardData = (from, to) => dispatch => {
  commonServices
    .httpGetCall(`/fetchDashBoardData/${from}/${to}`)
    .then(result => {
      console.log({ result });
      dispatch({ type: DASHBOARD.DASHBOARD, load: result.data.data });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: { type: 'error', message: err.response.data.message, show: true },
        });
      }
    });
};

export const sendPhoneOtp = data => dispatch => {
  commonServices
    .httpPostCall('/sendPhoneOtp', data)
    .then(result => {
      localStorage.setItem('phoneotptoken', result.data.data[0].token);
      dispatch({ type: USER.OTP_SENT, load: true });
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'OTP sent',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else if (err.response.data && err.response.data.errors) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.errors[0].msg,
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const checkPhoneOtp = data => dispatch => {
  commonServices
    .httpPatchCall('/checkPhoneOtp', data)
    .then(result => {
      localStorage.removeItem('otptoken');
      dispatch({ type: USER.PHONE_VERIFIED, load: true });
      dispatch({
        type: TOAST.TOAST,
        load: {
          type: 'success',
          message: 'OTP Verified',
          show: true,
          position: 'bottom-right',
        },
      });
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
            position: 'bottom-right',
          },
        });
      } else if (err.response.data && err.response.data.errors) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.errors[0].msg,
            show: true,
            position: 'bottom-right',
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: err.response.data.message,
            show: true,
            position: 'bottom-right',
          },
        });
      }
    });
};

export const fetchZoomOauth = data => dispatch => {
  commonServices
    .httpGetCall(`/business/connect-with-zoom?redirectUrl=${data}`)
    .then(result => {
      //   dispatch({ type: SUSBSCRIPTION.SUSBSCRIPTION, load: result.data.data });
      window.location.href = result.data.data;
    })
    .catch(err => {
      if (!err.response) {
        dispatch({
          type: TOAST.TOAST,
          load: {
            type: 'error',
            message: 'Oops! Something went wrong try again later.',
            show: true,
          },
        });
      } else {
        dispatch({
          type: TOAST.TOAST,
          load: { type: 'error', message: err.response.data.message, show: true },
        });
      }
    });
};

export const fetchZoomUsers = async (code, redirectUrl, token) => {
  const zoomUser = await commonServices.httpGetCall(
    `/activity/get-zoom-account/?code=${code}&redirectUrl=${redirectUrl}&token=${token}`,
  );
  return zoomUser;
};
