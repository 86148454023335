import React from 'react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Toast from './components/Toast';
import store from './redux/store';
import Router from './Router';
import { ConfirmationServiceProvider } from './services/ConfirmationService';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <>
    <Provider store={store}>
      <ConfirmationServiceProvider>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <Router />
          {/* Dev Tools fro react-query */}
          <ReactQueryDevtools />
        </ReactQueryCacheProvider>
      </ConfirmationServiceProvider>
      <Toast />
      <ToastContainer />
    </Provider>
  </>
);
export default App;
