import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Router as RouterV, Switch } from 'react-router-dom';
import { InvoiceServiceProvider } from './services/InvoiceModalService';
import history from './utils/history';

const DashboardContainer = lazy(() => import('./pages/DashboardContainer'));
const Signup = lazy(() => import('./pages/Signup/Signup'));
const Login = lazy(() => import('./pages/Login/Login'));
const PaymentSuccess = lazy(() => import('./pages/Payment/PaymentSuccess'));
const ForgotPassword = lazy(() => import('./pages/Login/ForgotPassword'));
const VerifyOtp = lazy(() => import('./pages/Login/verifyOtp'));
const ResetPassword = lazy(() => import('./pages/Login/ResetPassword'));
const NotVerified = lazy(() => import('./pages/Signup/NotVerified'));
const CalenderView = lazy(() => import('./pages/Calendar/Calendar'));
const ZoomDeauthorization = lazy(() => import('./pages/ZoomDeauthorization'));
const Zoom = lazy(() => import('./pages/Zoom/Zoom'));
const JoinManager = lazy(() => import('./pages/Login/JoinManager'));
const ErrorPage = lazy(() => import('./pages/Error/ErrorPage'));

const Router = () => (
  <RouterV basename={process.env.PUBLIC_URL} history={history}>
    <InvoiceServiceProvider>
      <Suspense
        fallback={
          <div className="lazy-loading-indicator">
            <p>
              Loading
              <div className="dot-pulse d-inline-block ml-4" />
            </p>
          </div>
        }
      >
        <Switch>
          <Route path="/error/:code" component={ErrorPage} />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/dashboard">
            <DashboardContainer />
          </Route>
          <Route path="/payment">
            <PaymentSuccess />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/not-verified">
            <NotVerified />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/verify-otp">
            <VerifyOtp />
          </Route>
          <Route path="/zoom">
            <Zoom />
          </Route>
          <Route path="/calendar/:business_id" component={CalenderView} />
          <Route path="/zoom-deauthorization" component={ZoomDeauthorization} />
          <Route path="/error/:code" component={ErrorPage} />

          {/* Branch Manager Join */}
          <Route path="/join" component={JoinManager} />

          {/* Default Route */}
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Suspense>
    </InvoiceServiceProvider>
  </RouterV>
);

export default Router;
