import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import errorReducer from './reducers/errorReducer';
import businessReducer from './reducers/businessReducer';
import activityReducer from './reducers/activityReducer';
import userReducer from './reducers/userReducer';
import peopleReducer from './reducers/peopleReducer';
import discountsReducer from './reducers/discountsReducer';
import subscriptionReducer from './reducers/subscriptionReducer';
import transactionReducer from './reducers/transactionReducer';
import paymentReducer from './reducers/paymentReducer';
import chatReducer from './reducers/chatReducer';
import inquiryReducer from './reducers/inquiryReducer';
import makeupClassReducer from './reducers/makeupClassReducer';
import attendeeReducer from './reducers/attendeeReducer';

const allReducers = combineReducers({
  user: userReducer,
  toastData: errorReducer,
  business: businessReducer,
  activity: activityReducer,
  people: peopleReducer,
  discount: discountsReducer,
  subscription: subscriptionReducer,
  transaction: transactionReducer,
  payment: paymentReducer,
  chat: chatReducer,
  inquiry: inquiryReducer,
  makeupClass: makeupClassReducer,
  attendees: attendeeReducer,
});

const rootReducer = (state, action) => {
  return allReducers(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk)),
);

export default configureStore;
