export const formatPrice = price => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (price !== undefined && price !== null) {
    return formatter.format(price);
  }

  return '';
};

export default formatPrice;
