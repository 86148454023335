const BASE_URL = process.env.REACT_APP_BASE_URL;
const siteURL = process.env.REACT_APP_SITE_URL;
const widgetURL = process.env.REACT_APP_WIDGET_URL;

export const siteUrl = siteURL;

export const WIDGET_URL = widgetURL;

export const adminUrl = `${BASE_URL}/admin`;

export default `${BASE_URL}/admin`;
