import { PEOPLE, RESET_APP } from '../actions';

const initial_state = {
  parent: [],
  coach: [],
  people_details: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case PEOPLE.PARRENT:
      return { ...state, parent: action.load };
    case PEOPLE.COACH:
      return { ...state, coach: action.load };
    case PEOPLE.PEOPLE_DETAILS:
      return { ...state, people_details: action.load };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
