import { RESET_APP, ATTENDEES } from '../actions';

const initial_state = {
  attendeesOnDate: [],
  holidayDetails: {},
  showAttendeesModal: false,
  showHolidaysModal: false,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case ATTENDEES.FETCH_ATTENDEES_ON_DATE:
      return { ...state, attendeesOnDate: action.load };
    case ATTENDEES.TOGGLE_ATTENDEES_MODAL:
      return { ...state, showAttendeesModal: !state.showAttendeesModal };
    case ATTENDEES.TOGGLE_CALENDAR_HOLIDAYS_MODAL:
      return { ...state, showHolidaysModal: !state.showHolidaysModal };
    case ATTENDEES.SET_HOLIDAYS:
      return { ...state, holidayDetails: { ...action.load } };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
