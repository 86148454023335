import { USER } from '../actions';

const initialState = {
  _id: undefined,
  userName: undefined,
  address: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  phone: undefined,
  profile_image: undefined,
  role: undefined,
};

export default (state = initialState, { type, load }) => {
  switch (type) {
    case USER.LOGIN:
      return { ...state, ...load };
    case USER.UPDATE:
      return { ...state, ...load };
    case USER.PHONE_VERIFIED:
      return { ...state, is_phone_verified: load };
    case USER.LOGOUT:
      return { ...initialState };
    default:
      return { ...state };
  }
};
