import { TOAST, RESET_APP } from '../actions';

const initial_state = {
  type: '',
  message: '',
  show: false,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case TOAST.TOAST:
      return { ...state, ...action.load };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
