import { SUSBSCRIPTION, RESET_APP } from '../actions';

const initial_state = {
  subscriptions: [],
  current_subscription: {},
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SUSBSCRIPTION.SUSBSCRIPTION:
      return { ...state, subscriptions: action.load };

    case SUSBSCRIPTION.CURRENT_SUBSCRIPTION:
      return { ...state, current_subscription: action.load };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
