import { MAKEUP_CLASS } from '../actions';

const initial_state = {
  hasNewMakeupClass: false,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case MAKEUP_CLASS.TOGGLE_HAS_NEW_MAKEUP_CLASS:
      return { ...state, hasNewMakeupClass: action.load };
    default:
      return { ...state };
  }
};
