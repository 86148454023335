/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { fetchInvoiceURL, sendPaymentLink } from '../../services/requests/invoiceRequests';
import {
  fetchSubscriptionURL,
  sendParentReferralCode,
  sendSubscriptionReferralCode,
} from '../../services/requests/subscriptionRequests';
import { formatPrice } from '../../utils/currency';

const moment = require('moment');

const InvoiceWithReferralCodeModal = ({ show, onHide, invoiceId, subscriptionId }) => {
  const businessId = useSelector(state => state?.business?.activeBusiness?._id);
  const [isLoading, setIsLoading] = useState(true);
  const [notifyParent, setNotifyParent] = useState(true);
  const [sendingMail, setSendingMail] = useState(false);
  const history = useHistory();

  const [invoice, setInvoice] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await fetchInvoiceURL(invoiceId, businessId);
      setInvoice(res.data);
    } catch (error) {
      toast.error(error?.message);
      setInvoice(null);
    }

    try {
      const res = await fetchSubscriptionURL(subscriptionId, businessId);
      setSubscription(res.data.subscription);
    } catch (error) {
      toast.error(error?.message);
      setSubscription(null);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (invoiceId && subscriptionId && businessId) {
      getData();
    }
  }, [invoiceId, subscriptionId, businessId]);

  const handleSendInvoiceLinkToParent = async () => {
    try {
      await sendPaymentLink(invoice.invoice.id, { email: invoice.invoice.customer_email });
      toast.success('Invoice sent');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const handleSendParentReferralCode = async () => {
    try {
      await sendParentReferralCode(subscription.parent._id, {});
      toast.success('Referral code sent to parent');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const handleSendSubscriptionReferralCode = async () => {
    try {
      await sendSubscriptionReferralCode(subscription._id, {});
      toast.success('Referral code sent to parent');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onClose = async () => {
    if (notifyParent === true) {
      setSendingMail(true);
      try {
        await sendSubscriptionReferralCode(subscription._id, {});
        toast.success('Referral code sent to parent');
      } catch (error) {
        toast.error('Something went wrong');
      } finally {
        setSendingMail(false);
        setNotifyParent(true);
        onHide();
      }
    } else {
      onHide();
    }
  };

  function handleOnClickInvoiceNumber() {
    onHide();
    history.push(`/dashboard/invoice/${invoice?.invoice?._id}`);
  }

  return (
    <Modal show={show} onHide={isLoading ? () => {} : onHide}>
      <Modal.Header className="m-3 text-center">
        <Modal.Title className="text-center text-primary w-100">Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mp-0">
        {isLoading ? (
          <div className="text-center">
            <Spinner
              animation="border"
              className="img-fluid img-fit-cover rounded-circle spinner-nav-zoom"
              variant="primary"
            />
          </div>
        ) : (
          <>
            {invoice && (
              <div className="text-center">
                <h6>
                  <span>Invoice for </span>
                  {invoice?.invoice?.customer_name}
                </h6>
                <div className="text-primary align-items-center">
                  <p
                    className="text-link d-inline-block cursor-pointer"
                    onClick={handleOnClickInvoiceNumber}
                  >
                    <b>{invoice?.invoice?.platform_invoice_id}</b>
                  </p>{' '}
                  |{' '}
                  <h5 className="text-success d-inline-block">
                    {formatPrice(invoice?.invoice?.amount_due)}
                  </h5>
                  <Button
                    variant="button-light"
                    size="sm"
                    className="ml-2 text-light"
                    onClick={handleSendInvoiceLinkToParent}
                    title={`Send invoice to ${invoice?.invoice?.customer_email}`}
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="text-primary" />
                  </Button>
                </div>
              </div>
            )}
            {subscription && (
              <div className="text-center">
                <h6>Subscription</h6>
                <p>
                  {`${subscription?.activity?.title} || `}
                  {moment(subscription.startsOn).format('ll')}
                  {' - '}
                  {moment(subscription.periodEndsOn).format('ll')}
                </p>

                {!subscription?.parent?.user?._id && (
                  <>
                    <h6>Parent referral code</h6>
                    <div className="d-flex justify-content-center">
                      <div className="w-75">
                        <InputGroup className="mb-3">
                          <FormControl
                            className="text-center"
                            readOnly
                            value={subscription.parent.referral_code}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              className="text-light"
                              title={`Send referral code to ${subscription.parent.email}`}
                              onClick={handleSendParentReferralCode}
                            >
                              <FontAwesomeIcon icon={faEnvelope} />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                  </>
                )}
                {!subscription?.student?.user?._id && (
                  <>
                    <h6>Subscription referral code</h6>
                    <div className="d-flex justify-content-center">
                      <div className="w-75">
                        <InputGroup className="mb-3">
                          <FormControl
                            className="text-center"
                            readOnly
                            value={subscription.referral_code}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              className="text-light"
                              title={`Send referral code to ${
                                subscription?.parent?.user?.email
                                  ? subscription?.parent?.user?.email
                                  : subscription?.parent?.email
                              }`}
                              onClick={handleSendSubscriptionReferralCode}
                            >
                              <FontAwesomeIcon icon={faEnvelope} />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                  </>
                )}

                <div className="d-flex justify-content-center">
                  <Form.Group className="mb-0 mt-5">
                    <Form.Check
                      inline
                      custom
                      type="checkbox"
                      checked={notifyParent}
                      onChange={() => setNotifyParent(!notifyParent)}
                      name="notify_parent"
                      id="notify_parent"
                      label="Notify parent"
                      style={{ fontSize: 18 }}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center m-3">
        <Button variant="primary" className="px-5" onClick={onClose}>
          {sendingMail ? (
            <Spinner animation="border" role="status" size="sm" variant="light" className="mr-2" />
          ) : null}
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceWithReferralCodeModal;
