import { PAYMENT, RESET_APP } from '../actions';

const initial_state = {
  payment_methods: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case PAYMENT.PAYMENT_METHODS:
      return { ...state, payment_methods: action.load };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
