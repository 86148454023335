import service from '../apiServices';
import http from '../http';

export const getActiveSubscriptions = ({
  parentPeopleId = undefined,
  activityId = undefined,
  page = 1,
  limit = 10,
}) => {
  const query = new URLSearchParams({ page, limit, paginate: true });
  if (parentPeopleId) query.append('parentPeopleId', parentPeopleId);
  if (activityId) query.append('activityId', activityId);
  return service.httpGetCall(`/payments/subscriptions/active?${query.toString()}`);
};

export const fetchNextClass = (subscriptionId, payload) =>
  service
    .httpPostCall(`/subscriptions/${subscriptionId}/next-class-date`, payload, false)
    .then(res => res.data);

export const sendParentReferralCode = (peopleId, payload) =>
  service
    .httpPostCall(`/people/${peopleId}/send-referral-code`, payload, false)
    .then(res => res.data);

export const sendSubscriptionReferralCode = (subscriptionId, payload = {}) =>
  service
    .httpPostCall(`/subscription/${subscriptionId}/send-referral-code`, payload, false)
    .then(res => res.data);

export const fetchCurrentSubscription = () =>
  http.get('/payment/activity-centre/current-subscription');

export const fetchSubscriptionURL = (subscriptionId, businessId) =>
  http.get(`/subscription/${subscriptionId}?business=${businessId}`);

export const pauseSubscription = (subscriptionId, data) =>
  http.post(`/subscription/${subscriptionId}/pause-payment-collection`, data);

export const resumeSubscription = subscriptionId =>
  http.post(`/subscription/${subscriptionId}/resume-payment-collection`);

export const getUpcomingInvoice = subscriptionId =>
  http.get(`/subscription/${subscriptionId}/upcoming-invoice-info`);

export const addCouponToSubscription = (subscriptionId, data) =>
  http.post(`/subscription/${subscriptionId}/add-coupon`, data);

export const removeCouponToSubscription = subscriptionId =>
  http.post(`/subscription/${subscriptionId}/remove-coupon`);

export const getSubscriptionInvoiceHistory = subscriptionId =>
  http.get(`/subscription/${subscriptionId}/invoice-history`);

export const requestReschedule = (subscriptionId, payload = {}) =>
  http.post(`/subscription/${subscriptionId}/reschedule`, payload, false);
