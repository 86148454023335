import commonServices from '../apiServices';
import http from '../http';

export const createInvoice = payload =>
  commonServices.httpPostCall('/invoice', payload).then(res => res.data);

export const fetchInvoices = query => http.get(`/invoice/v2?${query}`);

export const cancelInvoice = (invoiceId, payload) =>
  commonServices.httpPostCall(`/invoice/${invoiceId}/cancel`, payload).then(res => res.data);

export const updateInvoiceDescription = (invoiceId, payload) =>
  commonServices.httpPatchCall(`/invoice/${invoiceId}/description`, payload).then(res => res.data);

export const fetchInvoiceById = (invoiceId, businessId) =>
  commonServices.httpGetCall(`/invoice/${invoiceId}?business=${businessId}`).then(res => res.data);

export const sendPaymentLink = (invoiceId, payload) =>
  commonServices
    .httpPostCall(`/invoice/${invoiceId}/send-payment-link`, payload)
    .then(res => res.data);

export const payInvoice = (invoiceId, payload) =>
  commonServices.httpPostCall(`/invoice/${invoiceId}/pay-offline`, payload).then(res => res.data);

export const editInvoice = (invoiceId, payload) =>
  commonServices.httpPostCall(`/invoice/${invoiceId}/update-line-items`, payload);

export const fetchInvoiceURL = (invoiceId, businessId) =>
  http.get(`/invoice/${invoiceId}?business=${businessId}`);

export const toggleInvoiceAutoCharge = async (invoiceId, businessId) =>
  http
    .put(`/invoice/${invoiceId}/toggle-auto-charge`, {
      business: businessId,
    })
    .then(res => res.data);
export const downloadInvoicePdf = async (invoiceId, businessId, token) =>
  http
    .get(`/invoice/${invoiceId}/preview?business=${businessId}&authToken=${token}&download=true`)
    .then(res => res.data);

export const getInvoicePayoutDetails = invoiceId => {
  return commonServices.httpGetCall(`/invoice/${invoiceId}/payout-details`);
};

// export const getInvoicePayout = invoiceId => http.get(`/invoice/${invoiceId}/payout-details`);
