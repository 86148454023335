import { DISCOUNTS, RESET_APP } from '../actions';

const initial_state = {
  discounts: [],
  added_discount: {},
  discount_details: {},
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case DISCOUNTS.DISCOUNTS:
      return { ...state, discounts: action.load };
    case DISCOUNTS.ADDED:
      return { ...state, added_discount: action.load };
    case DISCOUNTS.DISCOUNT_DETAILS:
      return { ...state, discount_details: action.load };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
