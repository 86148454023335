import { TRANSACTION, RESET_APP } from '../actions';

const initial_state = {
  transactions: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case TRANSACTION.TRANSACTION:
      return { ...state, transactions: action.load };
    case RESET_APP:
      return { ...initial_state };
    default:
      return { ...state };
  }
};
