import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HideToast } from '../redux/actions/userActions';

const Toaster = props => {
  const { toastData } = props;
  const [show, setShow] = useState(toastData?.show);
  if (show !== toastData?.show) {
    setShow(toastData?.show);
  }

  const [toastClass, setToastClass] = useState('bg-primary text-white');
  const [toastPosition, setToastPosition] = useState('toast-bottom-right');

  useEffect(() => {
    switch (toastData?.type) {
      case 'error':
        setToastClass('bg-danger text-white');
        break;
      case 'success':
        setToastClass('bg-success text-white');
        break;
      case 'warning':
        setToastClass('bg-warning text-white');
        break;
      case 'light':
        setToastClass('bg-light text-primary');
        break;
      case 'dark':
        setToastClass('bg-dark text-white');
        break;
      default:
        setToastClass('bg-primary text-white');
        break;
    }

    switch (toastData?.position) {
      case 'top-left':
        setToastPosition('toast-top-left');
        break;
      case 'top-right':
        setToastPosition('toast-top-right');
        break;
      case 'top-center':
        setToastPosition('toast-top-center');
        break;
      case 'bottom-left':
        setToastPosition('toast-bottom-left');
        break;
      case 'bottom-right':
        setToastPosition('toast-bottom-right');
        break;
      case 'bottom-center':
        setToastPosition('toast-bottom-center');
        break;
      default:
        setToastPosition('toast-bottom-right');
        break;
    }
  }, [toastData, toastData?.position, toastData?.type]);

  return (
    <>
      {toastData?.type !== 'hide' && toastData?.message !== '' ? (
        <Toast
          onClose={() => props.HideToast()}
          show={show}
          delay={3000}
          autohide
          className={classnames(toastPosition)}
          style={{ zIndex: 4000 }}
        >
          <Toast.Body className={classnames(toastClass)}>{toastData?.message}</Toast.Body>
        </Toast>
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  toastData: state?.toastData,
});

export default connect(mapStateToProps, { HideToast })(Toaster);
