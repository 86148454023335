import { ACTIVITES, RESET_APP } from '../actions';

const initial_state = {
  activities: [],
  activity: {},
  activity_details: {},
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case ACTIVITES.ACTIVITES:
      return { ...state, activities: action.load };
    case ACTIVITES.ACTIVITY:
      return { ...state, activity: action.load };
    case ACTIVITES.ACTIVITY_DETAILS:
      return { ...state, activity_details: action.load };
    case RESET_APP:
      // console.log('reset app');
      return { ...initial_state };
    default:
      return { ...state };
  }
};
