import history from '../utils/history';
import { adminUrl } from './constants';

const axios = require('axios');

const services = {};
axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    // console.log('config.loaderType', config.loaderType);

    if (document.getElementsByClassName('view-container')[0]) {
      if (config.showLoader !== false) {
        if (config.loaderType === 'processing') {
          document
            .getElementsByClassName('view-container')[0]
            .classList.add('processing-indicator');
        } else {
          document.getElementsByClassName('view-container')[0].classList.add('loading-indicator');
        }
      }
    } else if (config.showLoader !== false) {
      document.body.classList.add('loading-indicator-body');
    }

    const token = localStorage.getItem('twishr-authtoken');
    if (token && token !== 'undefined') {
      config.headers.Authorization = `JWT ${token}`;
    }

    return config;
  },
  error => {
    setTimeout(() => {
      if (document.getElementsByClassName('view-container')[0]) {
        document.getElementsByClassName('view-container')[0].classList.remove('loading-indicator');
      }

      document.body.classList.remove('loading-indicator-body');
    }, 300);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    setTimeout(() => {
      if (document.getElementsByClassName('view-container')[0]) {
        document.getElementsByClassName('view-container')[0].classList.remove('loading-indicator');
        document
          .getElementsByClassName('view-container')[0]
          .classList.remove('processing-indicator');
      }

      document.body.classList.remove('loading-indicator-body');
    }, 300);
    return response;
  },
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  error => {
    setTimeout(() => {
      if (document.getElementsByClassName('view-container')[0]) {
        document.getElementsByClassName('view-container')[0].classList.remove('loading-indicator');
        document
          .getElementsByClassName('view-container')[0]
          .classList.remove('processing-indicator');
      }

      document.body.classList.remove('loading-indicator-body');
      if (error?.response?.status === 503 || error?.response?.status === 500) {
        const token = localStorage.getItem('twishr-authtoken');
        let url;
        if (token) {
          url = `/dashboard/error/${error?.response?.status}`;
        } else {
          url = `/error/${error?.response?.status}`;
        }

        history.push(url);
      }
    }, 300);
    return Promise.reject(error);
  },
);

services.axiosCall = option =>
  new Promise((resolve, reject) => {
    axios(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        // console.log(err);
        handleError(err);
        reject(err);
      });
  });

services.httpGetCall = (url, showLoader = true, loaderType = 'loading') =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'get',
      url: adminUrl + url,
      showLoader,
      loaderType,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

services.httpPostCall = (url, data, showLoader = true, loaderType = 'loading') =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'post',
      url: adminUrl + url,
      data,
      showLoader,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });
services.httpPutCall = (url, data, showLoader = true, loaderType = 'loading') =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'put',
      url: adminUrl + url,
      data,
      showLoader,
      loaderType,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

services.httpPatchCall = (
  url,
  data,
  uploadProgress = null,
  showLoader = true,
  loaderType = 'loading',
) =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'patch',
      url: adminUrl + url,
      data,
      onUploadProgress: uploadProgress,
      showLoader,
      loaderType,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

services.httpDeleteCall = (url, data = {}, showLoader = true, loaderType = 'loading') =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'delete',
      data,
      url: adminUrl + url,
      showLoader,
      loaderType,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

const handleError = err => {
  if (err && err.response && err.response.status === 401) {
    if (err.response.data && err.response.data.code === 'NOT_VERIFIED') {
      if (!window.location.pathname.includes('not-verified')) {
        window.location.href = '/not-verified';
      } else {
        // window.location.href = '/dashboard';
      }
    } else {
      localStorage.removeItem('twishr-authtoken');
      window.location.href = '/login';
    }
  }
};

export default services;
